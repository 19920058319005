import { graphql } from 'gatsby';
import Image from 'gatsby-image';
import React from 'react';
import { CircleArrow as ScrollUpButton } from 'react-scroll-up-button';
import SEO from '../components/seo';

const About = ({ data, location }) => (
  <>
    <SEO
      title="About"
      description="The Goodyear School of Dancing was founded in 1960, later renamed The Bath Dancentre. Paula and her associates teach in various locations in and around the Bath area."
      keywords={[
        `bath`,
        `dancentre`,
        `Paula Goodyear`,
        `Sonia Norris`,
        `about`,
      ]}
      location={location}
    />
    <ScrollUpButton style={{ zIndex: '99' }} />
    <section className="mb-5 md:mb-10">
      <h1 className="invisible">About The Bath Dance Centre</h1>
      <div className="flex flex-col items-center md:block">
        <figure className="mt-5 w-3/5 md:w-2/5 md:mt-0 md:float-right order-last md:order-none md:ml-3">
          <Image
            className="rounded h-64"
            fluid={data.about.sharp.fluid}
            imgStyle={{
              objectFit: 'cover',
              objectPosition: '50% 70%',
            }}
            alt="Young and old people dancing."
          />
        </figure>
        <p>
          The Goodyear School of Dancing was founded in 1960, later renamed The
          Bath Dancentre. Classes took place in the Forum, above the Bath City
          Free Church until 1997 when the owners decided not to renew the lease.
          Paula and her associates now teach in various locations in and around
          the Bath area.
        </p>
        <p>
          As well as general group medal and social dancing classes, Paula also
          teaches privately on individual or couple basis. Classes are as
          popular as ever and the list grows longer each year as styles such as
          Rock 'n' Roll and Salsa are added.
        </p>
      </div>
    </section>
    <section className="flex flex-col items-center md:block mb-5 md:mb-10">
      <h2 className="underline mb-2 md:mb-4">Our Teachers</h2>
      <figure className="mt-5 w-3/5 md:w-1/4 md:mt-0 md:float-left order-last md:order-none md:mr-8">
        <Image
          className="rounded h-64"
          fluid={data.paula.sharp.fluid}
          imgStyle={{
            objectFit: 'cover',
            objectPosition: '40% 20%',
          }}
          alt="Paula Goodyear"
        />
        <figcaption className="text-xs">
          Paula Goodyear with husband, Patrick.
        </figcaption>
      </figure>
      <h3>Paula Goodyear (Founder)</h3>
      <p>
        Paula had a very successful competitive career, achieving 3rd place
        Latin-American and 7th place Ballroom at the Open British Championships.
        She was a Ten Dance Champion and represented Britain many times,
        including The Team Match at The British.
      </p>
      <p>
        Paula is a Senior Examiner for the{' '}
        <a
          className="hover:underline text-highlight"
          href="https://www.istd.org/discover/our-governance/faculty-committees/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Imperial Society of Teachers of Dancing
        </a>{' '}
        (ISTD) and received the Imperial Dancesport Award in 2016 for dedication
        to the society. She has Fellowships in Ballroom, Latin/ American and
        Disco Freestyle plus a Licentiate in Rock n Roll.
      </p>
      <p>
        Paula taught dance at Bath University for many years. The students
        competitive team has gone from strength to strength achieving fifth
        place in the country. The Inter Varsity Dance Association presented
        Paula with an award in appreciation of her many years of devoted service
        to Student Dancesport.
      </p>
      <p>
        In 2019 the Ballroom Dancers’ Federation presented Paula with The
        Lifetime Achievement Award.
      </p>
    </section>
    <section className="flex flex-col items-center md:block">
      <figure className="mt-5 w-3/5 md:w-1/4 md:mt-0 md:float-right order-last md:order-none md:ml-8">
        <Image
          className="rounded h-64"
          fluid={data.sonia.sharp.fluid}
          imgStyle={{
            objectFit: 'cover',
            objectPosition: 'center top',
          }}
          alt="Sonia Norris"
        />
        <figcaption className="text-xs">Sonia Norris.</figcaption>
      </figure>

      <h3>Sonia Norris</h3>
      <p>
        Sonia joined The Bath Dancentre when she was ten years old, trained by
        Paula. Firstly taking all her medals in Ballroom and Latin-American and
        later training to be a Dance Teacher. She is highly qualified having
        achieved Fellowships in the ISTD in Ballroom and Latin-American. Also a
        Licentiate in Disco Freestyle. She has trained many Social Dancers,
        Medallists, Competitors, Professionals and Wedding couples for their
        first dance.
      </p>
    </section>
  </>
);

export const query = graphql`
  query {
    about: file(relativePath: { eq: "about.jpg" }) {
      sharp: childImageSharp {
        fluid(maxWidth: 300) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    paula: file(relativePath: { eq: "paula_and_patrick_p.jpg" }) {
      sharp: childImageSharp {
        fluid(maxWidth: 300) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    sonia: file(relativePath: { eq: "sonia_norris.jpg" }) {
      sharp: childImageSharp {
        fluid(maxWidth: 300) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

export default About;